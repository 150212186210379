import React, { Component } from "react"
import PropTypes from "prop-types"
import ReactHtmlParser from "react-html-parser"
import EmbeddedPlayer from "./EmbeddedPlayer"

class HtmlParser extends Component {
  render() {
    const transform = (node, index) => {
      // transform embedly-card to an embedded player
      if (
        node.type === `tag` &&
        node.name === `a` &&
        node.attribs.class === `embedly-card`
      ) {
        return (
          <div className="embed-responsive embed-responsive-16by9">
            <div>
              <EmbeddedPlayer url={node.attribs.href} />
            </div>
          </div>
        )
      } else {
        return node.data
      }
    }

    const options = {
      decodeEntities: true,
      transform,
    }

    let transformedHtml = ReactHtmlParser(this.props.html, options)

    return (
      <div className={this.props.entryClass ? `entry` : ``}>
        {transformedHtml}
      </div>
    )
  }
}

HtmlParser.propTypes = {
  html: PropTypes.string.isRequired,
  entryClass: PropTypes.bool,
}

export default HtmlParser
