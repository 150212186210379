import React, { Component } from "react"
import PropTypes from "prop-types"
import ReactPlayer from "react-player"
import InView from "react-intersection-observer"
import FigureBox, { boxFormats } from "./FigureBox"

const PREVIEW_MODE_CLASSNAME = `in-preview`

class EmbeddedPlayer extends Component {
  constructor(props) {
    super(props)
    this.startVideo = this.startVideo.bind(this)
    this.state = {
      previewClassName: PREVIEW_MODE_CLASSNAME,
      playing: false,
      visible: false,
      isstartVideo: false,
    }
  }

  componentDidMount() {
    // function handling fullscreen change event
    let fullscreenHandler = () => {
      // check if document is currently in fullscreen
      let isFullScreen =
        !document.webkitIsFullScreen &&
        !document.mozFullScreen &&
        !document.msFullscreenElement

      if (!isFullScreen) {
        this.setState({
          isFullScreen: true,
        })
      } else {
        this.setState({
          isFullScreen: false,
        })
      }
    }

    document.addEventListener(
      `webkitfullscreenchange`,
      fullscreenHandler,
      false
    )
    document.addEventListener(`mozfullscreenchange`, fullscreenHandler, false)
    document.addEventListener(`fullscreenchange`, fullscreenHandler, false)
    document.addEventListener(`MSFullscreenChange`, fullscreenHandler, false)
  }

  componentWillUnmount() {
    document.removeEventListener(`webkitfullscreenchange`, null)
    document.removeEventListener(`mozfullscreenchange`, null)
    document.removeEventListener(`fullscreenchange`, null)
    document.removeEventListener(`MSFullscreenChange`, null)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.refs.player.player &&
      this.state.playing &&
      !this.state.isVisible &&
      !this.state.isFullScreen
    ) {
      this.pauseVideo()
    }
  }

  previewImage = () => {
    if (this.props.previewImage) {
      return (
        <FigureBox
          format={boxFormats.auto}
          backgroundPosition={`center center`}
          source={this.props.previewImage}
          className="embedded_player__preview_image"
        />
      )
    }
  }

  playButton = () => {
    if (this.props.previewImage) {
      return (
        <a className="btn__play" role="button" onClick={() => this.startVideo()}>
          Play
        </a>
      )
    }
  }

  startVideo = () => {
    if (this.refs.player) {
      this.setState({
        previewClassName: ``,
        playing: true,
      })
    }
  }

  pauseVideo = () => {
    this.setState({
      previewClassName: PREVIEW_MODE_CLASSNAME,
      playing: false,
    })
  }

  setVisible(visible = false) {
    this.setState({
      isVisible: visible,
    })
  }

  setPlaying() {
    this.setState({
      playing: true,
    })
  }

  render() {
    return (
      <InView
        rootMargin="300px 0px 300px 0px"
        threshold={0.25}
        onChange={inView => this.setVisible(inView)}
      >
        <div
          className={`
            embedded_player
            embed-responsive-item
            ${this.state.previewClassName}
          `}
        >
          {this.playButton()}
          {this.previewImage()}
          <ReactPlayer
            ref="player"
            url={this.props.url}
            width="100%"
            height="100%"
            playing={this.state.playing}
            onStart={() => {
              this.setPlaying()
            }}
            onPlay={() => {
              this.startVideo()
            }}
            onPause={() => {
              this.pauseVideo()
            }}
            controls={true}
            config={{
              youtube: {
                playerVars: { showinfo: 0 },
              },
            }}
          />
        </div>
      </InView>
    )
  }
}

EmbeddedPlayer.propTypes = {
  url: PropTypes.string.isRequired,
  previewImage: PropTypes.object,
}

export default EmbeddedPlayer
